<template>
  <div class="w-full flex sm:mt-6 sm:flex-col sm:items-center flex-col text-[1.125rem] font-normal"
  style="font-family: 'MADE Sunflower'">
    Get the latest news about KEUKEN
    <div class="mt-8 rounded-[3.125rem] sm:rounded-[3.125rem] h-[3rem] bg-white w-full flex items-center">
      <input v-model="email" class="email"
             type="text" placeholder="Email Address"
             required style="box-shadow: none; font-family: 'Baskerville', sans-serif">
      <button class="flex min-w-[5.5rem] mr-2 sm:min-w-[4rem] sm:w-[10rem] rounded-[3.125rem] sm:rounded-[3.125rem] h-[2.25rem] sm:h-[2.25rem] text-[0.75rem]
                font-semibold border-0 bg-primary items-center text-white justify-center sm:text-[0.75rem] filter enabled:cursor-pointer"
              style="box-shadow: none; font-family: 'Baskerville', sans-serif"
              :disabled="!emailValid"
              @click="subscribe"
      >Subscribe</button>
    </div>
    <div class="w-full flex mt-2 sm:mt-1 sm:flex-col place-content-center items-center text-center text-[0.75rem]" style="font-family: 'Baskerville', sans-serif">
      <span v-if="subscribeSuccess == true">Sukses subscribe ke newsletter KEUKEN!</span> &nbsp;
      <span v-if="subscribeSuccess == false" >Email telah terdaftar di newsletter KEUKEN!</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue';
import {PUBLIC_API_URL} from '../utils/store.js';

const email = ref("");
const subscribeSuccess = ref();

const emailValid = computed(() => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
    return true;
  } else {
    return false;
  }
})

const subscribe = async () => {
  try {
    const response = await fetch(`${PUBLIC_API_URL}/Items/Subscribers`, {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({
        email: email.value,
      }),
    });
    subscribeSuccess.value = response.status === 204;
    email.value = ""
  } catch(e) {
    throw e
  }
}
</script>

<style lang="scss">
.email {
  @apply border-0 ml-3 mr-1 text-[1rem] text-black  w-full py-0 h-[2rem];
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
</style>
